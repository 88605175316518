import { Component, Vue, Prop } from 'vue-property-decorator';
import { authService } from '@/modules/account/services/authService';
import { accountServices } from '../services/accountService';

@Component({})
export default class EmailConfirm extends Vue {
  @Prop()
  userId: string;

  @Prop()
  code: string;

  hasValidated = false;
  loading = false;
  errors = '';

  mounted() {
    this.loading = true;
    setTimeout(() => {
      accountServices.ConfirmEmail(this.userId, this.code).then(
        () => {
          this.loading = false;
          this.hasValidated = true;
        },
        err => {
          this.loading = false;
          if (err && err.exceptionMessage) {
            this.errors = err.exceptionMessage;
          } else if (err && err.response && err.response.exceptionMessage) {
            this.errors = err.response.exceptionMessage;
          } else if (err && err.response && err.response.data && err.response.data.length > 0) {
            this.errors = (err.response.data as string[]).reduce((a, b) => `${a}, ${b}`);
          }
        }
      );
    }, 1000);
  }

  public backToLogin() {
    this.$router.push({ path: '/login' });
  }
}
